import { useEffect } from 'react'

const useDisableScroll = (disable: boolean): void => {
  useEffect(() => {
    const enableBodyScroll = (enableBodyScroll: boolean) => {
      const body = document.getElementsByTagName('body')?.[0]
      if (body) {
        if (window.innerWidth <= 1200) {
          body.style.overflow = enableBodyScroll ? '' : 'hidden'
        }
      }
    }
    enableBodyScroll(!disable)
    return () => {
      enableBodyScroll(true)
    }
  }, [disable])
}

export default useDisableScroll
