import {
  FC,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import styled, { css } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import MobileMenuNavigationBar, {
  MobileMenuNavigationBarTitleType
} from '@/components/molecules/mobileMenuNavigationBar'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import 'swiper/swiper.min.css'
import { makeCalcCss } from '@/utils/cssHelpers'
import { useWindowSize } from '@react-hook/window-size/throttled'
import useDisableScroll from 'hooks/useDisableScroll'

const AnimationDuration = 0.2
const NavigationBarHeight = 50
const FooterHeight = 65

export type SlidePage = {
  menuWidth?: number | string
  page: JSX.Element
  navigationBartTitle?: MobileMenuNavigationBarTitleType
}

type SlideMenuProps = {
  onDidClose?: () => void
  defaultNavigationBartTitle: MobileMenuNavigationBarTitleType
  pages: SlidePage[]
  stickyFooter?: JSX.Element
  menuPosition?: MenuPosition
  modal?: boolean
}

export type SlideMenuActions = {
  slideNext: () => void
  close: () => void
}

export enum MenuPosition {
  Left,
  Right
}

const SlideMenu = forwardRef<SlideMenuActions, SlideMenuProps>(
  (
    {
      defaultNavigationBartTitle,
      pages,
      stickyFooter,
      onDidClose,
      menuPosition,
      modal = true
    },
    ref
  ) => {
    const [show, setShow] = useState<boolean>(false)
    const [presentInDom, setPresentInDom] = useState<boolean>(false)
    const [swiper, setSwiper] = useState<SwiperClass>(null)
    const [width, height] = useWindowSize()
    const [activeIndex, setActiveIndex] = useState<number>(0)
    useImperativeHandle(ref, () => ({
      close: handleClose,
      slideNext: () => {
        swiper.slideNext()
        setActiveIndex(swiper.activeIndex)
      }
    }))

    useEffect(() => {
      setPresentInDom(true)
    }, [])
    useEffect(() => {
      if (presentInDom) {
        setShow(true)
      }
    }, [presentInDom])

    const handleClickBack = () => {
      if (swiper.activeIndex === 0) {
        handleClose()
        return
      }
      swiper.slidePrev()
      setActiveIndex(swiper.activeIndex)
    }
    const handleClose = () => {
      setShow(false)
      setTimeout(() => {
        setPresentInDom(false)
        onDidClose && onDidClose()
      }, AnimationDuration * 1000)
    }

    const getMenuWidth = useCallback(() => {
      const relativeMenuWidth = pages[activeIndex].menuWidth || 276
      let menuWidth = relativeMenuWidth
      if (relativeMenuWidth.toString().indexOf('%') !== -1) {
        menuWidth =
          (parseFloat(relativeMenuWidth.toString().replace('%', '')) / 100) *
          width
      }
      return menuWidth
    }, [width, pages[activeIndex].menuWidth])

    const navigationBartTitle =
      pages[activeIndex].navigationBartTitle || defaultNavigationBartTitle

    const footerHeight = stickyFooter ? FooterHeight : 0

    useDisableScroll(modal || show)

    return !presentInDom ? null : (
      <MobileMenuWrapper menuPosition={menuPosition}>
        <MenuItemsWrapper
          menuWidth={getMenuWidth()}
          show={show}
          menuPosition={menuPosition}
        >
          <StyledMobileMenuNavigationBar
            menuWidth={getMenuWidth()}
            title={navigationBartTitle}
            show={show}
            onClickBack={handleClickBack}
            onClickClose={handleClose}
            data-cy="mobileMenuNavigationBar"
            hideArrow={activeIndex >= 1}
            menuPosition={menuPosition}
          />
          <Box mt={NavigationBarHeight} pb={footerHeight}>
            <Swiper
              onInit={(swiper) => setSwiper(swiper)}
              allowTouchMove={false}
            >
              {pages.map(({ page }, offset) => (
                <SwiperSlide key={offset}>
                  <PageItem
                    menuWidth={getMenuWidth()}
                    menuHeight={height}
                    footerHeight={footerHeight}
                  >
                    {page}
                  </PageItem>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <StickyFooter show={show} menuWidth={getMenuWidth()}>
            {stickyFooter}
          </StickyFooter>
        </MenuItemsWrapper>
        {modal && <Overlay show={show} onClick={handleClose} />}
      </MobileMenuWrapper>
    )
  }
)

export default SlideMenu

const PageItem: FC<Rest> = ({ children, ...rest }) => (
  <PageWrapper {...rest}>
    <Box px={12}>{children}</Box>
  </PageWrapper>
)

const MobileMenuWrapper = styled(Flex)`
  position: fixed;
  ${(props) =>
    props.menuPosition === MenuPosition.Right ? 'right:0' : 'left:0'};
  top: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 102;
`

type MountedProps = {
  show: boolean
}

type MenuWidthProps = {
  menuWidth: number
}

type MenuHeightProps = {
  menuHeight: number
}

// width doesn't work on chrome, when parent is absolute and overflow-y is scroll
const MenuItemsWrapper = styled<MenuWidthProps & MountedProps>(Box)`
  min-width: ${(props) => props.menuWidth}px;
  max-width: ${(props) => props.menuWidth}px;
  background-color: ${(props) => props.theme.colors.background};

  ${(props) =>
    animatePositionChange(
      props.menuPosition === MenuPosition.Right
        ? 'margin-right'
        : 'margin-left',
      props.show,
      props.menuWidth
    )};
`

const PageWrapper = styled<MenuWidthProps & MenuHeightProps>(Box)`
  width: ${(props) => props.menuWidth}px !important;
  height: -webkit-fill-available;
  overflow-y: auto;
  height: ${(props) =>
    props.menuHeight
      ? `${props.menuHeight - (NavigationBarHeight + props.footerHeight)}px`
      : makeCalcCss(
          'max-height',
          `100vh - ${NavigationBarHeight + props.footerHeight}px`
        )};
`

const transitionCss = css`
  transition: ${AnimationDuration}s ease-out;
`

const animatePositionChange = (
  cssProperty: string,
  show: boolean,
  componentWidth: number
): css =>
  css`
    ${cssProperty}: ${show ? componentWidth : 0}px;
    ${transitionCss};
    ${cssProperty}: ${show ? 0 : -componentWidth}px;
  `

// When it is relative, it doesn't work well with iOS safari, as it is below of bottom navigation bar of safari
const StyledMobileMenuNavigationBar = styled<MountedProps & MenuWidthProps>(
  MobileMenuNavigationBar
)`
  position: fixed;
  top: 0;
  width: ${(props) => props.menuWidth}px;
  height: ${NavigationBarHeight}px;
  ${(props) =>
    animatePositionChange(
      props.menuPosition === MenuPosition.Right
        ? 'margin-right'
        : 'margin-left',
      props.show,
      props.menuWidth
    )};
`

const Overlay = styled<MountedProps>(Box)`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: ${(props) => (props.show ? 0 : 0.54)};
  ${transitionCss};
  opacity: ${(props) => (props.show ? 0.54 : 0)};
  position: fixed;
  z-index: -1;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
`

const StickyFooter = styled(Box)`
  z-index: 15;
  position: fixed;
  left: 0;
  bottom: 0;
  width: ${(props) => props.menuWidth}px;
  ${(props) =>
    animatePositionChange(
      props.menuPosition === MenuPosition.Right
        ? 'margin-right'
        : 'margin-left',
      props.show,
      props.menuWidth
    )};
`
