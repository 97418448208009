import { FC } from 'react'
import Image from 'next/image'
import styled, { useTheme } from 'styled-components'
import AnimatedExpandIcon from '@/components/atoms/animatedExpandIcon'
import { Capitalized, Tiny2 } from '@/components/atoms/typography'
import { Box, Flex } from '@/components/atoms/Grid'
import MenuBadge from '@/components/atoms/menuBadge'
import { NextArrowIconDefault } from '../atoms/icons/NextArrowIconDefault'
import { SlugPathItem } from '@/types/slugPath'
import { IconButtonReverse } from './iconButtons/iconButton'
import { DiscountCouponIconDefault } from '../atoms/icons/DiscountCouponIconDefault'

export enum ArrowState {
  opened,
  closed,
  next,
  none
}

type MobileMenuItemProps = {
  title: string
  subtitle?: string
  imageUrl?: string
  arrowState?: ArrowState
  onClick: () => void
  isMain?: boolean
  highlight?: boolean
  badge?: string
  isActive?: SlugPathItem
  isBigButton?: boolean
} & Rest

const MobileMenuBox: FC<MobileMenuItemProps> = ({
  title,
  subtitle,
  imageUrl,
  onClick,
  arrowState = ArrowState.none,
  isMain = true,
  highlight = false,
  badge,
  isActive,
  isBigButton,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <MobileMenuWrapper
      isMain={isMain}
      onClick={onClick}
      justifyContent="space-between"
      {...rest}
    >
      {!isBigButton ? (
        <Flex justifyContent="center" alignItems="start" flexDirection="column">
          <Flex alignItems="center">
            {badge && (
              <MenuBadge
                imageUrl={badge}
                maxHeight={30}
                maxWidth={100}
                mr={10}
                ml={18}
              />
            )}
            <StyledBox withBadge={badge} isActive={isActive}>
              {isMain ? (
                <StyledCapitalized
                  highlight={highlight}
                  m={0}
                  isActive={isActive}
                >
                  {title}
                </StyledCapitalized>
              ) : (
                <StyledTiny2 highlight={highlight} m={0}>
                  {title}
                </StyledTiny2>
              )}
              {subtitle && (
                <Tiny2 opacity={0.6} mt="6px" mb={0}>
                  {subtitle}
                </Tiny2>
              )}
            </StyledBox>
          </Flex>
        </Flex>
      ) : (
        <StyledIconButtonReverse
          title={title.toUpperCase()}
          IconElement={
            <DiscountCouponIconDefault
              color={theme.colors.accent}
              secondarycolor={theme.colors.menuBackground}
            />
          }
        />
      )}

      <Flex flexDirection="row" mr={arrowState === ArrowState.none ? 0 : 2}>
        {imageUrl?.length > 0 && !isBigButton && (
          <Image src={imageUrl} width={86} height={50} unoptimized />
        )}
        {arrowState !== ArrowState.none && makeArrowIcon(arrowState)}
      </Flex>
    </MobileMenuWrapper>
  )
}

export default MobileMenuBox

const makeArrowIcon = (expanderState: ArrowState): JSX.Element => {
  switch (expanderState) {
    case ArrowState.next:
      return <NextArrowIconDefault width={12} height={8} opacity={0.54} />
    case ArrowState.opened:
    case ArrowState.closed:
      return (
        <AnimatedExpandIcon
          isOpened={expanderState === ArrowState.opened}
          data-cy="mobileMenuArrowDownUpIcon"
        />
      )
  }
}

const MobileMenuWrapper = styled(Flex)`
  min-height: 50px;
  &:hover {
    cursor: pointer;
  }
  background-color: ${(props) =>
    props.isMain
      ? props.theme.colors.menuItemBackgroundSecondary
      : props.theme.colors.background};
  border: ${(props) =>
    props.isMain ? 'none' : `1px solid ${props.theme.colors.tableBackground}`};
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
`

const StyledTiny2 = styled(Tiny2)`
  font-weight: normal;
  opacity: 0.6;
  color: ${(props) =>
    props.highlight
      ? props.theme.colors.accent
      : props.theme.colors.onBackground};
`

const StyledCapitalized = styled(Capitalized)`
  text-transform: uppercase;
  color: ${(props) =>
    props.highlight
      ? props.theme.colors.accent
      : props.theme.colors.onBackground};
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    width: 16px;
    height: 2px;
    background-color: ${(props) =>
      props.highlight
        ? props.theme.colors.accent
        : props.theme.colors.onBackground};
  }
`

const StyledBox = styled(Box)`
  padding: ${(props) => (props.isActive ? '18px 18px 14px' : '18px')};
  ${(props) =>
    props.withBadge
      ? `        
        padding-left: 0px;        
      `
      : ''};
`

const StyledIconButtonReverse = styled(IconButtonReverse)`
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.menuBackground};
  width: 100%;
  min-height: 50px;
  //margin-top: -20px;
  //margin-bottom: 20px;
  &:hover {
    background-color: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.menuBackground};
  }
`
