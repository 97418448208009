import styled from 'styled-components'
import { ArrowDownIconDefault } from './icons/ArrowDownIconDefault'

type AnimatedArrowDownProps = {
  isOpened: boolean
}

const AnimatedExpandIcon = styled<AnimatedArrowDownProps>(ArrowDownIconDefault)`
  transform: rotate(${(props) => (props.isOpened ? 0 : 180)}deg);
  transition: 0.2s ease-out;
  transform: rotate(${(props) => (props.isOpened ? 180 : 0)}deg);
`

export default AnimatedExpandIcon
