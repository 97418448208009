import { FC } from 'react'
import { SvgProps } from './svgProps'

const MenuBackIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 14}
    height={height || 15}
    viewBox="0 0 14 15"
  >
    <defs>
      <filter id="lgixq0qoaa" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g transform="translate(-95 -171)">
        <g stroke={color || '#151414'} strokeWidth="2">
          <path
            d="M0 6.503L13 6.503 6.74 0"
            transform="matrix(-1 0 0 1 109 172)"
          />
          <path
            d="M0 13L13 13 6.74 6.497"
            transform="matrix(-1 0 0 1 109 172) matrix(1 0 0 -1 0 19.497)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default MenuBackIcon
