import { FC } from 'react'
import { SvgProps } from './svgProps'
const DiscountCouponIcon: FC<SvgProps> = ({
  width,
  height,
  color,
  secondarycolor
}) => {
  return (
    <svg
      fill={color || '#ffffff'}
      height={height || 34}
      width={width || 34}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-294 -294 1078.00 1078.00"
    >
      <g strokeWidth="0" transform="translate(0,0), scale(1)">
        <rect
          x="-294"
          y="-294"
          width="1078.00"
          height="1078.00"
          rx="539"
          fill={secondarycolor || '#000000'}
        />
      </g>
      <g>
        <g>
          <path d="M96.383,245.798c27.77,0,51.146-10.631,70.106-31.879c18.97-21.247,28.45-51.538,28.45-90.865 c0-40.38-9.374-70.981-28.133-91.811C148.046,10.422,124.365,0,95.761,0C64.667,0,40.926,11.635,24.563,34.889 C8.188,58.15,0,86.377,0,119.568c0,42.293,9.163,73.898,27.511,94.827C45.86,235.326,68.814,245.798,96.383,245.798z M62.646,57.724c9.222-12.262,20.781-18.397,34.665-18.397c13.885,0,25.502,6.084,34.83,18.238 c9.327,12.161,13.991,33.25,13.991,63.273c0,32.564-4.617,54.974-13.839,67.236c-9.233,12.262-20.885,18.397-34.982,18.397 c-13.673,0-25.186-6.077-34.512-18.238c-9.327-12.154-13.991-33.142-13.991-62.956C48.809,92.51,53.425,69.993,62.646,57.724z" />{' '}
          <polygon points="143.947,490 392.372,0 346.981,0 97.628,490 " />
          <path d="M390.82,244.21c-31.305,0-55.14,11.576-71.515,34.729c-16.375,23.153-24.563,51.43-24.563,84.832 c0,42.083,9.174,73.637,27.523,94.676C340.602,479.477,363.556,490,391.126,490c27.993,0,51.464-10.624,70.423-31.871 C480.52,436.875,490,406.591,490,367.264c0-40.381-9.374-70.99-28.134-91.818C443.106,254.624,419.425,244.21,390.82,244.21z M427.354,432.277c-9.222,12.269-20.992,18.397-35.288,18.397c-13.685,0-25.186-6.127-34.513-18.397 c-9.326-12.255-13.99-33.192-13.99-62.798c0-32.766,4.605-55.283,13.838-67.546c9.221-12.263,20.874-18.398,34.971-18.398 c13.884,0,25.503,6.078,34.829,18.239c9.327,12.161,13.991,33.249,13.991,63.266C441.191,397.605,436.574,420.022,427.354,432.277z " />{' '}
        </g>
      </g>
    </svg>
  )
}
export default DiscountCouponIcon
