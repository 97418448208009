import { FC } from 'react'
import { SvgProps } from './svgProps'

const CategoriesIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '18'}
    height={height || '18'}
    viewBox="0 0 18 18"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H18V18H0z"
            transform="translate(-373.000000, -296.000000) translate(373.000000, 296.000000)"
          />
          <g stroke={color || '#000'} strokeLinecap="round" strokeWidth="1.5">
            <path
              d="M4 .3L14.2.3M4 5.3L14.2 5.3M4 10.3L14.2 10.3M0 .3L.6.3M0 5.3L.6 5.3M0 10.3L.6 10.3"
              transform="translate(-373.000000, -296.000000) translate(373.000000, 296.000000) translate(2.400000, 3.700000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CategoriesIcon
