import { FC } from 'react'
import { Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'
import { ListItemSeparator } from '@/components/atoms/separator'
import { Body2 } from '@/components/atoms/typography'
import { MenuPosition } from '@/components/organisms/slideMenu'
import { MenuBackIconDefault } from '../atoms/icons/MenuBackIconDefault'
import { MenuCloseIconDefault } from '../atoms/icons/MenuCloseIconDefault'

export type MobileMenuNavigationBarTitleType = JSX.Element | string

type MobileMenuNavigationBarProps = {
  onClickBack: () => void
  onClickClose: () => void
  title: MobileMenuNavigationBarTitleType
  menuPosition: MenuPosition
  hideArrow: boolean
}

const MobileMenuNavigationBar: FC<MobileMenuNavigationBarProps & Rest> = ({
  onClickBack,
  onClickClose,
  hideArrow,
  menuPosition,
  title,
  ...rest
}) => {
  const Title = typeof title === 'string' ? <Body2 m={0}>{title}</Body2> : title
  return (
    <Wrapper flexDirection="column" {...rest}>
      <Flex
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        flexGrow={1}
      >
        {hideArrow ? (
          <StyledMenuBackIcon
            onClick={onClickBack}
            ml={3}
            cursorOnHover={true}
            data-cy="mobileMenuBackIcon"
            menuPosition={menuPosition}
          />
        ) : (
          <ArrowIconHidden />
        )}
        {Title}
        <MenuCloseIconDefault
          mr={3}
          onClick={onClickClose}
          cursorOnHover={true}
          data-cy="mobileMenuCloseIcon"
        />
      </Flex>
      <ListItemSeparator />
    </Wrapper>
  )
}
export default MobileMenuNavigationBar

const Wrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.background};
  z-index: 10;
`
const StyledMenuBackIcon = styled(MenuBackIconDefault)`
  margin-left: 10px;
  ${(props) =>
    props.menuPosition === MenuPosition.Right && 'transform:rotate(180deg)'};
`
const ArrowIconHidden = styled.div`
  min-width: 24px;
`
